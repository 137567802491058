import styles from '../../styles/PageSection.module.css'
import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import Image from "next/image";

const PageSection = (
  props: {
    children: React.ReactNode,
    title?: string,
    subtitle?: string,
    image?: string,
    backgroundColor?: string,
    backgroundImage?: string
  }
) => {
  return(
    <div
      className={`${styles.container} pb-5`}
      style={{
        ...(props.backgroundImage ? {backgroundImage: `url(${props.backgroundImage})`} : {}),
        ...(props.backgroundColor ? {backgroundColor: props.backgroundColor} : {})
      }}
    >
      <Container fluid="lg">
        <Row>
          <Col>
            {props.image &&
              <div className={[styles.image, 'mb-4 text-center'].join(' ')}>
                <Image width={120} height={30} src={props.image} className='img-fluid mh-100' alt='' />
              </div>
            }
            {props.title && <h2 className={`text-center ${(props.image ? 'mb-5' : 'my-5')}`}>{props.title}</h2>}
            {props.subtitle && <div>{props.subtitle}</div>}
            <div className='w-100'>
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PageSection