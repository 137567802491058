import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../../styles/Carousel.module.css'
import React from "react";

const Carousel = React.forwardRef((
  props: {
    variant?: 'simple' | 'box-selector' | 'box-details' | 'toy-selector' | 'toy-list',
    children: any,
    slidesToShow: number,
    slidesToShowXl: number,
    slidesToShowLg: number,
    slidesToShowMd: number,
    slidesToShowSm: number,
    infinite?: boolean,
    dots?: boolean,
    centerMode?: boolean,
    centerModeMobile?: boolean,
    centerPadding?: string,
    arrows?: boolean,
    setCurrentSlide?: any,
    draggable?: boolean,
    focusOnSelect?: boolean
  },
  ref?: any
) => {

  const length = React.Children.count(props.children)

  const NextArrow = (props: any) => {
    const { variant, slidesCount, slidesToShow, currentSlide, className, style, onClick } = props
    // if (currentSlide >= slidesCount - slidesToShow && (variant === 'box-selector')) return <></>
    return (
      <div
        className={[className, styles.arrow, 'arrow-next'].join(' ')}
        onClick={onClick}
      />
    );
  }

  const PrevArrow = (props: any) => {
    const { variant, currentSlide, className, style, onClick } = props
    if (currentSlide === 0 && (variant === 'box-selector')) return <></>
    return (
      <div
        className={[className, styles.arrow, 'arrow-prev'].join(' ')}
        onClick={onClick}
      />
    );
  }

  const settings = {
    waitForAnimate: true,
    ...(props.setCurrentSlide ? { afterChange: (current: number) => props.setCurrentSlide(current) } : {}),
    arrows: props.arrows,
    draggable: props.draggable ? props.draggable : false,
    swipe: true,
    dots: props.dots,
    speed: 300,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    centerMode: props.centerMode,
    centerPadding: props.centerPadding ? props.centerPadding : '0',
    focusOnSelect: props.focusOnSelect ? props.focusOnSelect : false,
    infinite: props.infinite,
    nextArrow: <NextArrow variant={props.variant} slidesToShow={props.slidesToShow} slidesCount={React.Children.count(props.children)} />,
    prevArrow: <PrevArrow variant={props.variant} />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: props.slidesToShowXl,
          ...((props.variant === 'simple' && React.Children.count(props.children) > props.slidesToShowXl) && { infinite: true })
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: props.slidesToShowLg,
          ...((props.variant === 'simple' && React.Children.count(props.children) > props.slidesToShowLg) && { infinite: true })
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: props.slidesToShowMd,
          ...((props.variant === 'simple' && React.Children.count(props.children) > props.slidesToShowMd) && { infinite: true })
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.slidesToShowSm,
          centerMode: props.centerModeMobile ? props.centerModeMobile : props.centerMode,
          ...((props.variant === 'simple' && React.Children.count(props.children) > props.slidesToShowSm) && { infinite: true })
        }
      }
    ]
  }

  return(
    <Slider className={props.variant} {...settings} ref={ref}>
      {props.children}
    </Slider>
  )
})

Carousel.displayName = 'Carousel'

export default Carousel
