import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichText } from '../RichText';
import { useAppContext } from '../../context/shared';
import styles from '../../styles/SubscriptionCard.module.css';
import {
  type SubscriptionVariant,
  type SubscriptionData,
} from '../../lib/types';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons';

type SubscriptionCardProps = {
  readonly subscription: SubscriptionData;
  readonly isDisabled?: boolean;
  readonly isSmall?: boolean;
  readonly isSelected?: boolean;
  readonly isGhost?: boolean;
  readonly onSubscribeClick?: (subscription: SubscriptionVariant) => void;
};

export function SubscriptionCard(props: SubscriptionCardProps) {
  const { subscription, isSmall, isSelected, isDisabled, isGhost } = props;
  const { localization } = useAppContext();

  let colorStyle = '';
  switch (subscription.frequency) {
    case 'annual':
    case 'annualMonthly': {
      colorStyle = styles.cardGreen;
      break;
    }

    case 'monthly': {
      colorStyle = styles.cardBlue;
      break;
    }

    case 'biannual':
    case 'biannualMonthly': {
      colorStyle = styles.cardOrange;
      break;
    }

    // No default
  }

  const handleSubscribeClick = () => {
    if (props.onSubscribeClick && !isDisabled) {
      props.onSubscribeClick(subscription.frequency);
    }
  };

  const cardClassName = `${styles.card} ${!isSelected && colorStyle} ${isGhost && styles.cardGhost} h-100`;
  return (
    <div className={styles.container}>
      <Card className={cardClassName}>
        <Card.Body className="d-flex flex-column">
          <div className={styles.content}>
            <div className="pb-4">
              <p className={['h3 fw-bold', styles.title].join(' ')}>
                {subscription.title}
              </p>
              {props.subscription.bestValue && (
                <div
                  className={`${styles.bestValueSticker} h5 text-center mt-2`}
                >
                  {localization.subscription_best_deal}
                </div>
              )}
            </div>
            {subscription.totalPayment && (
              <RichText
                className={styles.totalPayment}
                content={subscription.totalPayment}
              />
            )}

            <p className={`${styles.price} h2 subtitle text-black fw-bold`}>
              €{subscription.monthlyPayment}
              {localization.cart_price_per_month}
            </p>
          </div>
          {!isSmall && (
            <>
              <hr />
              <div className={styles.benefits}>
                {props.subscription.items.map((item) => (
                  <div
                    key={item.content}
                    className="d-flex align-items-center mb-4"
                  >
                    <FontAwesomeIcon
                      icon={item.notIncluded ? faCircleXmark : faCircleCheck}
                      className={[
                        'me-2',
                        styles.checkIcon,
                        item.notIncluded ? styles.cross : styles.check,
                      ].join(' ')}
                    />
                    <RichText
                      className={styles.subscriptionContent}
                      content={item.content}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {props.onSubscribeClick && (
            <Button
              className="w-100"
              size="lg"
              disabled={props.isDisabled}
              onClick={handleSubscribeClick}
            >
              {subscription.ctaText}
            </Button>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
