import React from 'react';
import Carousel from '../Carousel';

interface CarouselOnMobileProps {
  readonly desktopChildren?: React.ReactNode;
  readonly mobileChildren?: React.ReactNode;
}

export default function CarouselOnMobile(props: CarouselOnMobileProps) {
  return (
    <>
      <div className="carousel-desktop">{props.desktopChildren}</div>
      <div className="carousel-mobile">
        <Carousel
          dots
          draggable
          variant="simple"
          slidesToShow={1}
          slidesToShowXl={1}
          slidesToShowLg={1}
          slidesToShowMd={1}
          slidesToShowSm={1}
          centerModeMobile={true}
          centerMode={true}
        >
          {props.mobileChildren}
        </Carousel>
      </div>
    </>
  );
}
