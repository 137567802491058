import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import styles from '../../styles/RichText.module.css';

type RichTextProps = {
  readonly content: string;
  readonly className?: string;
};

export function RichText(props: RichTextProps) {
  const { content, className = '' } = props;
  const [parsedContent, setParsedContent] = useState('');

  useEffect(() => {
    if (content) {
      setParsedContent(content.replaceAll('\\', ''));
    }
  }, [content]);

  if (!content) return <span />;

  return (
    <div className={`richText ${styles.container} ${className}`}>
      {parse(parsedContent)}
    </div>
  );
}
