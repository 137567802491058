import { Col, Container, Row } from 'react-bootstrap';
import styles from '../../styles/HowItWorksStep.module.css';
import Image from 'next/image';

const HowItWorksStep = (props: {
  icon?: string;
  title?: string;
  description?: string;
}) => {
  return (
    <Container className="d-flex flex-column">
      <Row>
        <Col className="text-center">
          <Image
            width={295}
            height={295}
            src={props.icon || ''}
            className="img-fluid mh-100 mx-auto mb-1"
            alt=""
          />
        </Col>
      </Row>
      <Row className="flex-grow-1">
        <Col className="p-4 text-center">
          {props.title && <p className={styles.title}>{props.title}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default HowItWorksStep;
