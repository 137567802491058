import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { TRIBU_MAIN_TITLE } from '../../lib/constants';
import { useAppContext } from '../../context/shared';

export function PageHead(props: {
  readonly title?: string;
  readonly description?: string;
  readonly image?: string;
}) {
  const { config } = useAppContext();

  let titlePrefix = TRIBU_MAIN_TITLE;
  if (config.metaTitle) {
    titlePrefix = config.metaTitle;
  }

  const titleSuffix = props.title;

  const pageTitle = titlePrefix + (titleSuffix ? ` | ${titleSuffix}` : '');

  const [pageUrl, setPageUrl] = useState('/');
  useEffect(() => {
    setPageUrl(window.location.href.split('?')[0]);
  }, []);

  return (
    <Head>
      <title>{pageTitle}</title>

      <meta
        name="description"
        content={props.description ?? config.metaDescription ?? ''}
      />
      <link rel="canonical" href={pageUrl} />
      <meta property="og:url" content="https://tribu-box.com/" />
      <meta property="og:type" content="website" />

      <meta
        key="og_title"
        property="og:title"
        content={props.title ?? config.metaTitle ?? TRIBU_MAIN_TITLE}
      />
      <meta
        key="og_description"
        property="og:description"
        content={props.description ?? config.metaDescription ?? ''}
      />
      <meta
        key="og_image"
        property="og:image"
        content={props.image ?? config.metaImage ?? ''}
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta
        key="twitter_title"
        property="twitter:title"
        content={props.title ?? config.metaTitle ?? TRIBU_MAIN_TITLE}
      />
      <meta
        key="twitter_description"
        property="twitter:description"
        content={props.description ?? config.metaDescription ?? ''}
      />
      <meta
        key="twitter_image"
        property="twitter:image"
        content={props.image ?? config.metaImage ?? ''}
      />
    </Head>
  );
}
