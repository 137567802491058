import React from 'react';
import styles from '../../styles/Common.module.css';

export function HeaderWithDescription(props: {
  readonly title: string;
  readonly description?: string | React.ReactNode;
  readonly id?: string;
  readonly className?: string;
  readonly imageUrl?: string;
}) {
  return (
    <div className={props.className}>
      <div className={`${styles.headerWithFetti} mt-4`}>
        <h2 id={props.id}>{props.title}</h2>
        {props.imageUrl && (
          <div>
            <img src={props.imageUrl} className="mx-3" alt="" />
          </div>
        )}
      </div>
      <h4 className="mt-3 fw-normal font-family-base">{props.description}</h4>
    </div>
  );
}
