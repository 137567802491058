import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import {
  type SubscriptionVariant,
  type SubscriptionData,
} from '../../lib/types';
import { SubscriptionCard } from '../SubscriptionCard';
import { useAppContext } from '../../context/shared';
import styles from '../../styles/SubscriptionsList.module.css';
import CarouselOnMobile from '../CarouselOnMobile';

type SubscriptionsListProps = {
  readonly subscriptions: SubscriptionData[];
  readonly isCartReady?: boolean;
  readonly isSmall?: boolean;
  readonly selectedSubscription?: SubscriptionVariant;
  readonly onSubscribeClick?: (subscription: SubscriptionVariant) => void;
};

export function SubscriptionsList(props: SubscriptionsListProps) {
  const {
    subscriptions,
    isCartReady,
    isSmall,
    selectedSubscription,
    onSubscribeClick,
  } = props;

  const { localization, locale, isMobile } = useAppContext();
  const [showPrepaid, setShowPrepaid] = React.useState(true);
  const [filteredSubscriptions, setFilteredSubscriptions] = React.useState<
    Array<SubscriptionData>
  >([]);

  const filterOnPrepaidSelection = (subscription: SubscriptionData) => {
    if (showPrepaid) {
      return ['annual', 'biannual'].includes(subscription.frequency);
    }

    return ['annualMonthly', 'biannualMonthly', 'monthly'].includes(
      subscription.frequency
    );
  };

  const fakeMonthlySubscription = subscriptions.find((subscription) => {
    return subscription.frequency === 'monthly';
  });

  React.useEffect(() => {
    const subscriptionsByPayment = subscriptions.filter(
      filterOnPrepaidSelection
    );

    if (isMobile) {
      // Best value subscription is always first on mobile
      const bestPriceSubscriptionIndex = subscriptionsByPayment.findIndex(
        (subscription) => subscription.bestValue
      );
      const bestPriceSubscription =
        subscriptionsByPayment[bestPriceSubscriptionIndex];
      subscriptionsByPayment.splice(bestPriceSubscriptionIndex, 1);
      subscriptionsByPayment.unshift(bestPriceSubscription);
      setFilteredSubscriptions(subscriptionsByPayment);
    } else {
      setFilteredSubscriptions(subscriptionsByPayment);
    }
  }, [showPrepaid, subscriptions, isMobile]);

  return (
    <>
      <div className="justify-content-center">
        <div
          className={[
            'd-flex justify-content-center p-2 rounded',
            styles.buttonsWrapper,
          ].join(' ')}
        >
          <Button
            className={[
              styles.toggleButton,
              styles[
                showPrepaid ? 'toggleButtonActive' : 'toggleButtonInactive'
              ],
            ].join(' ')}
            onClick={() => {
              setShowPrepaid(true);
            }}
          >
            {localization.cart_toggle_prepayment_label}
          </Button>
          <Button
            className={[
              styles.toggleButton,
              styles[
                showPrepaid ? 'toggleButtonInactive' : 'toggleButtonActive'
              ],
            ].join(' ')}
            onClick={() => {
              setShowPrepaid(false);
            }}
          >
            {localization.cart_toggle_monthly_label}
          </Button>
          <div
            className={[
              styles.discount,
              !showPrepaid ? styles.disableDiscount : '',
            ].join(' ')}
          >
            <p>{locale === 'de' ? 'Spare 8-10%' : 'Save 8% · 10%'}</p>
          </div>
        </div>
      </div>
      <div className={`${isSmall ? 'gy-5' : 'px-xl-5 g-5'} my-5`}>
        <CarouselOnMobile
          desktopChildren={
            <Row
              xs={1}
              lg={3}
              className={`${isSmall ? 'gy-5' : 'px-xl-5 g-5'} my-5 justify-content-around`}
            >
              {filteredSubscriptions.map((subscription: SubscriptionData) => (
                <Col
                  key={subscription.frequency}
                  className={`${
                    subscription.bestValue
                      ? styles.orderFirstOnMobile
                      : styles.orderLastOnMobile
                  } animate__animated animate__fadeIn px-0 ${subscription.bestValue} px-3`}
                >
                  <SubscriptionCard
                    isSelected={selectedSubscription === subscription.frequency}
                    isSmall={isSmall}
                    isDisabled={isCartReady}
                    subscription={subscription}
                    onSubscribeClick={onSubscribeClick}
                  />
                </Col>
              ))}
              {showPrepaid && fakeMonthlySubscription && (
                <Col
                  className={`${styles.orderLastOnMobile} "animate__animated animate__fadeIn"`}
                >
                  <SubscriptionCard
                    isDisabled
                    isGhost
                    isSmall={isSmall}
                    subscription={fakeMonthlySubscription}
                    onSubscribeClick={onSubscribeClick}
                  />
                </Col>
              )}
            </Row>
          }
          mobileChildren={filteredSubscriptions.map(
            (subscription: SubscriptionData) => (
              <SubscriptionCard
                key={subscription.frequency}
                isSelected={selectedSubscription === subscription.frequency}
                isSmall={isSmall}
                isDisabled={isCartReady}
                subscription={subscription}
                onSubscribeClick={onSubscribeClick}
              />
            )
          )}
        ></CarouselOnMobile>
      </div>
    </>
  );
}
